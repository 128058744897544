.text-general-info {
    color: #170E00;
    font-family: 'Literata', serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }

.no-members-text {
  color:  #79747E;
  text-align: center;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  align-self: stretch;
}